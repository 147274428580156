import React from 'react';
import * as yup from 'yup';

import {
  DELIVERY_SERVICE_OPTION,
  PACKAGE_TYPE,
  PICKUP_SERVICE_OPTION
} from '../constants/quote.constants';
import { convertTimeStamp } from './date_conversion.util';
import { BID_TYPE, BID_STATUS } from '../constants/bids.constants';
import { LTL_FREIGHT } from '../constants/service_type.constants';
import { VENDORS } from '../constants/carrier.constants';

const getServiceTypeName = (serviceType) => {
  switch (serviceType) {
    case '5805d83787ae495159586545':
      return 'LTL Freight';

    case '5805d83787ae495159586547':
      return 'Truckload - Flatbed';

    case '5805d83787ae495159586546':
      return 'Blanket Wrap';

    case '5805d83787ae495159586548':
      return 'Truckload - Van';

    case '5805d83787ae49515958654a':
      return 'Partial Direct Flatbed';

    case '5805d83787ae495159586549':
      return 'Partial Direct Van';

    default:
      break;
  }
};

const getSummaryPath = (quote) => {
  const { loadType, id, bidStatus, quoteId } = quote;
  const { SALES_REP_PENDING_QUOTE, ENROUTE_QUOTE } = BID_STATUS;
  switch (loadType) {
    case 'carrier-available-loads':
      return {
        pathname: `/carrier-available-load-details/${id}`,
        state: { loadType }
      };

    case 'carrier-accepted-loads':
    case 'carrier-pending-loads':
    case 'carrier-rejected-loads':
    case 'carrier-completed-loads':
      return {
        pathname: `/carrier-load-details/${quoteId}/${id}`,
        state: { loadType }
      };

    case 'carrier-assigned-loads':
    case 'carrier-enroute-loads':
      return {
        pathname: `/carrier-load-details/${quoteId}/${id}`,
        state: { loadType }
      };
    case 'shipper-active-shipment':
      if (bidStatus === SALES_REP_PENDING_QUOTE) {
        return `/shipper-load-summary/${id}`;
      }

      if (bidStatus === ENROUTE_QUOTE) {
        return `/shipper-enroute-load-summary/${id}`;
      }

      return {
        pathname: `/shipper-submission-list/${id}`,
        state: quote
      };

    case 'shipper-pending-loads':
    case 'shipper-recent-quotes':
    case 'shipper-completed-loads':
      return `/shipper-load-summary/${id}`;

    case 'shipper-enroute-loads':
      return `/shipper-enroute-load-summary/${id}`;

    case 'salesRep-active-quotes':
      if (bidStatus === SALES_REP_PENDING_QUOTE) {
        return `/salesRep/quote-details/${id}`;
      }
      if (bidStatus === ENROUTE_QUOTE) {
        return `/salesRep/enroute-quote-details/${id}`;
      }
      return {
        pathname: `/salesRep/shipper-submission-list/${id}`,
        state: quote
      };

    case 'salesRep-enroute-quotes':
      return `/salesRep/enroute-quote-details/${id}`;

    case 'salesRep-pending-quotes':
    case 'salesRep-recent-quotes':
    case 'salesRep-completed-quotes':
    case 'salesRep-open-invoices':
      return `/salesRep/quote-details/${id}`;

    default:
      break;
  }
};

const toCamelCase = (data) => {
  const firstChar = data?.charAt(0)?.toUpperCase();
  const restOfString = data?.slice(1);

  // Check if both firstChar and restOfString are defined before concatenating
  if (firstChar !== undefined && restOfString !== undefined) {
    return firstChar + restOfString;
  }
  // Handle the case where either firstChar or restOfString is undefined
  // You might want to return something appropriate or throw an error
  return ''; // or throw new Error('Invalid data');
};

const getPickupDeliveryDate = (date) => {
  if (typeof date === 'string') {
    return date;
  }
  return convertTimeStamp(date);
};

const printServiceOptions = (options, isMultipleQuote, type) => {
  const service =
    type === 'PICKUP' ? PICKUP_SERVICE_OPTION : DELIVERY_SERVICE_OPTION;
  return options.length ? (
    options.map((option) => {
      return (
        <p key={option.id} className="text-xl font-bold text-left">
          {isMultipleQuote
            ? option
                ?.map((opt) => {
                  return service.find((pick) => pick.value === opt)?.label;
                })
                ?.join(', ')
            : option.display_name ||
              service.find((opt) => opt.value === option)?.label}
        </p>
      );
    })
  ) : (
    <p className="text-xl font-bold text-left">No Service Selected</p>
  );
};

const calculateDensity = (cargo) => {
  const cubitFeet = (
    (parseFloat(cargo.length) *
      parseFloat(cargo.width) *
      parseFloat(cargo.height) *
      parseFloat(cargo.quantity ?? 1)) /
    1728
  ).toFixed(2);

  // Support ticket #10605026
  // Reference: https://performancefreight.com/density-calculator/
  const density = (
    (parseFloat(cargo.quantity ?? 1) * cargo.weight) /
    cubitFeet
  ).toFixed(2);
  return { cubitFeet, density };
};

const cargoStringConvertor = (cargos) => {
  const cargoType = {};
  cargos?.forEach((cargo) => {
    const packageTypeId =
      typeof cargo.package_type === 'string'
        ? cargo.package_type
        : cargo.package_type?.[0]?.id;

    const packageType = cargoType[packageTypeId];

    cargoType[packageTypeId] = !packageType
      ? 1 * cargo.quantity
      : packageType + 1 * cargo.quantity;
  });

  let str = '';
  let index = 1;
  const allCargoType = Object.keys(cargoType);
  allCargoType?.forEach((key) => {
    const packageName = PACKAGE_TYPE.find((pack) => pack.value === key)?.label;
    str +=
      `${cargoType[key]} ${packageName}` +
      `${index === allCargoType?.length ? '' : ', '}`;
    index += 1;
  });
  return str;
};

const pickupValidationSchema = yup.object({
  pickup_from: yup.string().required('Pickup from is required'),
  pickup_formatted_address: yup
    .string()
    .required('Pickup formatted address is required'),
  pickup_zip_code: yup.string().required('Zip code is required'),
  pickup_date: yup.string().when('is_pickup_flexible', {
    is: false,
    then: () => yup.string().required('Pick up date is required')
  }),
  is_pickup_flexible: yup.bool(),
  pickup_time: yup.string().when('is_pickup_time_flexible', {
    is: false,
    then: () => yup.string().required('Pick up time is required')
  }),
  pickup_close_time: yup.string(),
  is_pickup_time_flexible: yup.bool(),
  pickup_service_options: yup.array().nullable(),
  pickup_special_instructions: yup.string()
});

const deliveryValidationSchema = yup.object({
  delivery_to: yup.string().required('Delivery from is required'),
  delivery_formatted_address: yup
    .string()
    .required('Delivery formatted address is required'),
  delivery_zip_code: yup.string().required('Zip code is required'),
  delivery_date: yup.string().when('is_delivery_flexible', {
    is: false,
    then: () => yup.string().required('Pick up date is required')
  }),
  is_delivery_flexible: yup.bool(),
  delivery_time: yup.string().when('is_delivery_time_flexible', {
    is: false,
    then: () => yup.string().required('Pick up time is required')
  }),
  delivery_close_time: yup.string(),
  is_delivery_time_flexible: yup.bool(),
  delivery_service_options: yup.array().nullable(),
  delivery_special_instructions: yup.string()
});

const getInsuranceMarkup = (isInsuranceRequired, insuranceValue) => {
  if (isInsuranceRequired) {
    const markup = Number(insuranceValue / 1000) * 7;

    if (markup < 125) {
      return 125;
    }
    return markup;
  }
  return 0;
};

const additionalCharges = (quote) => {
  let revenueCharge = 0;
  let carrierCharge = 0;

  quote?.additional_charges?.forEach((addData) => {
    if (addData.feesType === 'revenue') {
      revenueCharge += Number(addData.fees);
    } else if (addData.feesType === 'carrier') {
      carrierCharge += Number(addData.fees);
    }
  });
  return { revenueCharge, carrierCharge };
};

const getCarrierPrice = (quote) => {
  if (quote?.bidType === BID_TYPE.QUOTE_FROM_NET) {
    const amount = (
      Number(quote?.estimate) -
      (getInsuranceMarkup(
        quote?.is_insurance_required,
        quote?.insurance_value
      ) +
        Number(quote?.commission)) +
      additionalCharges(quote).carrierCharge +
      Number(quote?.tarps_value_carrier)
    ).toFixed(2);

    return `$${amount}`;
  }
  if (quote?.bidType === BID_TYPE.FIXED_AMOUNT) {
    const amount = (
      Number(quote?.amount) -
      (getInsuranceMarkup(
        quote?.is_insurance_required,
        quote?.insurance_value
      ) +
        Number(quote?.netCharge)) +
      additionalCharges(quote).carrierCharge +
      Number(quote?.tarps_value_carrier)
    ).toFixed(2);
    return `$${amount}`;
  }
  if (quote?.bidType === BID_TYPE.OPEN_BID) {
    const amount = (
      Number(quote?.amount) +
      additionalCharges(quote).carrierCharge +
      Number(quote?.tarps_value_carrier)
    ).toFixed(2);

    return quote?.bidStatus === BID_STATUS.ENROUTE_QUOTE ||
      quote?.bidStatus === BID_STATUS.DELIVERED_QUOTE
      ? `$${amount}`
      : 'Open Bid';
  }
};

const getQuotePrice = (quote) => {
  if (quote?.bidType === BID_TYPE.QUOTE_FROM_NET) {
    if (quote?.service_type === LTL_FREIGHT) {
      if (quote?.booked) {
        return (
          Number(quote[quote?.carrier_type]?.estimate) +
          Number(quote?.tarps_value) +
          additionalCharges(quote).revenueCharge
        ).toFixed(2);
      }
      const carriers = VENDORS.map((vendor) => vendor.name);
      const rates = [];
      carriers.forEach((carrier) => {
        const rate = quote[carrier]?.estimate;
        if (rate && rate !== 0) rates.push(rate);
      });

      if (!rates.length) return 0;
      const min = Math.min(...rates);
      return min;
    }
    return (
      Number(quote?.estimate) +
      Number(quote?.tarps_value) +
      additionalCharges(quote).revenueCharge
    ).toFixed(2);
  }
  if (quote?.bidType === BID_TYPE.OPEN_BID) {
    return (
      Number(quote?.amount) +
      Number(quote?.tarps_value) +
      Number(quote?.netCharge) +
      getInsuranceMarkup(quote?.is_insurance_required, quote?.insurance_value) +
      additionalCharges(quote).revenueCharge
    ).toFixed(2);
  }
  if (quote?.bidType === BID_TYPE.FIXED_AMOUNT) {
    return (
      Number(quote?.amount) +
      Number(quote?.tarps_value) +
      additionalCharges(quote).revenueCharge
    ).toFixed(2);
  }
};

const getBookingStatus = (quote, carrierType) => {
  let quoteAmount;
  if (quote?.service_type === LTL_FREIGHT && !carrierType) return false;
  if (quote?.service_type === LTL_FREIGHT && carrierType) {
    const updatedQuote = { ...quote };
    updatedQuote.carrier_type = carrierType;
    quoteAmount = getQuotePrice(updatedQuote);
  } else {
    quoteAmount = getQuotePrice(quote);
  }
  if (
    Number(quoteAmount) <=
      Number(quote?.company?.creditLimit) -
        Number(quote?.company?.openBalance) ||
    quote?.allowCreditLimitExceedBooking
  ) {
    return true;
  }
  return false;
};

const getDefaultFreightValue = (density) => {
  let value = { value: '0', label: 'Select Freight Class' };

  if (density > 0 && density < 1) {
    value = { value: '400', label: '400' };
  } else if (density >= 1 && density < 2) {
    value = { value: '300', label: '300' };
  } else if (density >= 2 && density < 4) {
    value = { value: '250', label: '250' };
  } else if (density >= 4 && density < 6) {
    value = { value: '150', label: '150' };
  } else if (density >= 6 && density < 8) {
    value = { value: '125', label: '125' };
  } else if (density >= 8 && density < 10) {
    value = { value: '100', label: '100' };
  } else if (density >= 10 && density < 12) {
    value = { value: '92.5', label: '92.5' };
  } else if (density >= 12 && density < 15) {
    value = { value: '85', label: '85' };
  } else if (density > 15) {
    value = { value: '70', label: '70' };
  }

  return value;
};

export {
  getServiceTypeName,
  getSummaryPath,
  toCamelCase,
  getPickupDeliveryDate,
  printServiceOptions,
  calculateDensity,
  cargoStringConvertor,
  getInsuranceMarkup,
  pickupValidationSchema,
  deliveryValidationSchema,
  additionalCharges,
  getQuotePrice,
  getBookingStatus,
  getCarrierPrice,
  getDefaultFreightValue
};
