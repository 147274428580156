import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import Select from 'react-select';

import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId
} from 'react-places-autocomplete';

import { IoLocationSharp } from 'react-icons/io5';
import moment from 'moment';
import DatePickerInput from '../../../components/DatePickerInput/DatePickerInput';
import TimePickerInput from '../../../components/TimePickerInput/TimePickerInput';
import {
  PICKUP_SERVICE_OPTION,
  PICKUP_LIMITED_ACCESS_TYPES
} from '../../../constants/quote.constants';
import {
  LTL_FREIGHT,
  PICKUP_BUSSINESS_WITH_DOCK_FORKLIFT,
  PICKUP_RESIDENTIAL,
  PICKUP_INSIDE_PICKUP,
  PICKUP_LIMITED_ACCESS
} from '../../../constants/service_type.constants';

import { ReactComponent as LeftArrowIcon } from '../../../assets/images/left_arrow.svg';
import { loadAddressService } from '../../../services/quote.service';
import { useAuthContext } from '../../../contexts/auth.context';
import { getAddressFromLatLong } from '../../../services/geo.service';
import { formatPickupDeliveryTime } from '../../../utils/date_conversion.util';
import { addLocationService } from '../../../services/shipper.service';
import { pickupValidationSchema } from '../../../utils/quote.util';
import Roles from '../../../constants/roles.constants';
// import { getAddressFromLatLong } from '../../../services/geo.service';

const ShipperSinglePickUP = ({ prevStep, formContent }) => {
  const validationSchema = pickupValidationSchema;

  const [isDateDisable, setDateDisable] = useState(false);
  const [isTimeDisable, setTimeDisable] = useState(false);
  const [pickupMessage, setpickupMessage] = useState(false);
  const [address, setAddress] = useState('');
  const [userAddress, setUserAddress] = useState([]);
  const [IsChangeAdd, setIsChangeAdd] = useState(false);
  const [IsFromList, setIsFromList] = useState(false);

  const { authUser } = useAuthContext();
  const serviceType = formContent.one.service_type;

  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useFormContext();

  const isPickupFlexible = watch('is_pickup_flexible');

  const isPickupTimeFlexible = watch('is_pickup_time_flexible');

  const pickUpServices = watch('pickup_service_options');
  const pickupServiceOptions = watch('pickup_service_options');

  const handleSelect = async (value, placeId) => {
    try {
      let id = placeId;
      if (!id) {
        const result = await geocodeByAddress(value);
        id = result[0].place_id;
      }
      const [place] = await geocodeByPlaceId(id.trim());
      const { addr, postcode, city, state, country } =
        await getAddressFromLatLong(
          place.geometry.location.lat(),
          place.geometry.location.lng(),
          value,
          place.postcode_localities
        );
      setAddress(value);
      setValue(
        'pickup_from',
        `${addr} | ${postcode}, ${city}, ${state}, ${country}`
      );
      setValue('pickup_formatted_address', `${value} | ${placeId}`);
      setValue('pickup_zip_code', postcode);
      setValue('pickup_state', state);
    } catch (error) {
      console.log(error);
    }
  };

  async function loadAddresses() {
    try {
      const companyId =
        authUser?.user?.usertype === Roles.SHIPPER
          ? authUser?.user?.companyId?.id
          : getValues('company')?.id;
      const response = await loadAddressService(companyId, 'pickup');

      setUserAddress(response.data.data);
      const defaultAddress = response.data.data?.filter?.(
        (addr) => addr.isDefault
      )?.[0];
      const pickupAdd = getValues('pickup_formatted_address')?.split('|')?.[0];
      if (pickupAdd) {
        setAddress(pickupAdd);
      } else {
        handleSelect(defaultAddress?.name, defaultAddress?.locationId);
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
    loadAddresses();
  }, []);

  useEffect(() => {
    const addrData = userAddress?.find(
      (pick) => pick?.pickup_formatted_address?.trim() === address
    );

    if (addrData) {
      setValue(
        'pickup_time',
        addrData?.pickup_time !== 'flexible time'
          ? new Date(moment(addrData.pickup_time, 'h:mm a').format())
          : ''
      );
      setValue(
        'pickup_close_time',
        addrData?.pickup_close_time
          ? new Date(moment(addrData.pickup_close_time, 'h:mm a').format())
          : ''
      );
      setValue('is_pickup_time_flexible', addrData?.is_pickup_time_flexible);
      setValue('pickup_service_options', addrData?.pickup_service_options);
      setValue(
        'pickup_special_instructions',
        addrData?.pickup_special_instructions
      );
      addrData?.service_options_type?.forEach((serviceOptionType) => {
        const matchedService = PICKUP_LIMITED_ACCESS_TYPES.find(
          (type) => type.value === serviceOptionType
        );
        setValue('pickup_limited_access_type', matchedService);
      });
    }

    if (userAddress?.length && addrData) {
      setIsFromList(true);
      setValue('pickupLocation', addrData?.id);
    } else if (!getValues('pickupLocation')) {
      setIsFromList(false);
      setValue('pickupLocation', null);
    } else {
      setIsFromList(false);
    }
  }, [address]);

  useEffect(() => {
    if (isPickupFlexible) {
      setValue('pickup_date', '', { shouldValidate: true });
      setDateDisable(true);
    } else {
      setDateDisable(false);
    }
  }, [isPickupFlexible]);

  useEffect(() => {
    if (isPickupTimeFlexible) {
      setValue('pickup_time', '', { shouldValidate: true });
      setValue('pickup_close_time', '', { shouldValidate: true });
      setTimeDisable(true);
    } else {
      setTimeDisable(false);
    }
  }, [isPickupTimeFlexible]);

  useEffect(() => {
    if (typeof pickUpServices === 'string' && pickUpServices.length) {
      setValue('pickup_service_options', [pickUpServices]);
    }
    if (pickUpServices && pickUpServices.length) {
      if (
        serviceType === LTL_FREIGHT &&
        pickUpServices.includes(PICKUP_BUSSINESS_WITH_DOCK_FORKLIFT) &&
        pickUpServices.includes(PICKUP_RESIDENTIAL)
      ) {
        setValue(
          'pickup_service_options',
          pickUpServices.filter(
            (opt) =>
              opt !== PICKUP_BUSSINESS_WITH_DOCK_FORKLIFT &&
              opt !== PICKUP_RESIDENTIAL
          ),
          {
            shouldValidate: true
          }
        );
        toast.error(
          'You can select either Business with a Dock or Forklift or Residential at a time!'
        );
      }
      if (!pickUpServices.includes(PICKUP_LIMITED_ACCESS)) {
        setValue('delivery_limited_access_type', undefined);
      }
      if (pickUpServices.includes(PICKUP_INSIDE_PICKUP) && !pickupMessage) {
        toast.success(
          'Please fill up special instructions for us at the bottom of this page, since you have selected inside pick up.'
        );
        setpickupMessage(true);
      }
    }
  }, [pickUpServices]);

  const saveNewAddress = async () => {
    try {
      const companyId =
        authUser?.user?.usertype === Roles.SHIPPER
          ? authUser?.user?.companyId?.id
          : getValues('company')?.id;
      const pickupData = {
        locationId: getValues('pickup_formatted_address')
          ?.split('|')?.[1]
          ?.trim(),
        name: getValues('pickup_formatted_address')?.split('|')?.[0]?.trim(),
        pickup_formatted_address: getValues('pickup_formatted_address')
          ?.split('|')?.[0]
          ?.trim(),
        pickup_from: getValues('pickup_from'),
        pickup_zip_code: getValues('pickup_zip_code'),
        type: 'pickup',
        companyId,
        pickup_time: formatPickupDeliveryTime(
          getValues('pickup_time'),
          getValues('is_pickup_time_flexible')
        ),
        pickup_close_time: !getValues('pickup_close_time')
          ? ''
          : formatPickupDeliveryTime(getValues('pickup_close_time'), false),
        pickup_service_options: getValues('pickup_service_options'),
        service_options_type: [getValues('pickup_limited_access_type')]
          ?.filter((type) => type)
          .map((option) => option.value),
        is_pickup_time_flexible: getValues('is_pickup_time_flexible'),
        pickup_special_instructions: getValues('pickup_special_instructions')
      };

      validationSchema
        .validate(pickupData, { abortEarly: false })
        .then(async () => {
          const response = await addLocationService(pickupData);
          toast.success('Pickup address saved successfully!');
          setUserAddress([...userAddress, response?.data?.data]);
          setIsFromList(true);
        })
        .catch((error) => {
          console.log(error);
          return toast.error('Invalid data');
        });
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const suggestionsDivRef = useRef(null);

  const onAddressChange = (e) => {
    suggestionsDivRef.current.scrollTop = 0;
    setIsChangeAdd(true);
    setAddress(e);
  };
  return (
    <>
      {userAddress && (
        <>
          {' '}
          <div className="relative p-4 text-navy-500">
            <div
              onClick={prevStep}
              onKeyPress={() => {}}
              role="button"
              tabIndex="-1"
            >
              <LeftArrowIcon className="absolute inset-y-0 left-0 flex items-center w-6 h-6 mt-6 font-bold text-navy-500" />
            </div>
            <h3 className="ml-6 text-2xl font-extrabold">
              Select pickup option
            </h3>
          </div>
          <div className="p-4 mb-3">
            <label
              className="block w-full mb-2 text-sm font-bold text-black"
              htmlFor="ship-from"
            >
              Ship From
            </label>
            <PlacesAutocomplete
              value={address}
              onChange={(e) => onAddressChange(e)}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <div>
                  <div className="flex items-center gap-3">
                    <input
                      className="w-full px-2 py-3 text-xs leading-tight bg-gray-100 border border-gray-200 border-solid shadow appearance-none"
                      {...getInputProps({ placeholder: 'Type address' })}
                    />
                    {!IsChangeAdd && (
                      <div className="inline-flex justify-end w-1/5">
                        <button
                          type="button"
                          onClick={() => setIsChangeAdd(true)}
                          className="flex items-center h-10 gap-1 px-2 py-2 text-sm break-words whitespace-normal border rounded-sm max-w-max border-navy-500 hover:bg-navy-600 hover:text-white active:bg-navy-700 text-navy-500 hover:border-navy-600 active:border-navy-700"
                        >
                          <IoLocationSharp size={20} />
                          Change Address
                        </button>
                      </div>
                    )}
                  </div>

                  <div
                    className="overflow-scroll max-h-48"
                    ref={suggestionsDivRef}
                  >
                    {IsChangeAdd && (
                      <div className="px-2 py-2 mt-2 space-y-3 bg-gray-100 border border-gray-200 border-solid shadow-lg">
                        <div className="mt-3 bg-gray-100">
                          {suggestions.length > 0 && (
                            <>
                              <div className="flex flex-col gap-2">
                                <p className="text-gray-400">Other Address</p>
                                {loading ? <div>...loading</div> : null}
                                {suggestions.map((suggestion) => {
                                  return (
                                    <React.Fragment key={suggestion.placeId}>
                                      <label
                                        {...getSuggestionItemProps(suggestion)}
                                      >
                                        <input
                                          {...register(`selectedAddress`)}
                                          type="radio"
                                          value={suggestion.id}
                                        />

                                        <span className="">
                                          {suggestion.description}
                                        </span>
                                      </label>
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                              <hr className="mt-3" />
                            </>
                          )}
                        </div>
                        <p className="text-gray-400">Default Address</p>
                        <div className="flex flex-col mt-4 mb-4">
                          {userAddress
                            ?.filter((addr) => addr.isDefault)
                            ?.map((addr) => {
                              return (
                                <label key={addr.id}>
                                  <input
                                    {...register(`selectedAddress`)}
                                    type="radio"
                                    value={addr.id}
                                    checked={addr.name === address}
                                    onChange={() =>
                                      handleSelect(addr.name, addr.locationId)
                                    }
                                  />
                                  <span>{addr.name}</span>
                                </label>
                              );
                            })}
                        </div>
                        <hr />
                        <div>
                          <p className="text-gray-400">Saved Address</p>
                          <div className="flex flex-col gap-2 mt-4 mb-4 ">
                            {userAddress
                              ?.filter((addr) => !addr.isDefault)
                              ?.map((addr) => {
                                return (
                                  <label key={addr.id}>
                                    <input
                                      {...register(`selectedAddress`)}
                                      type="radio"
                                      value={addr.id}
                                      onChange={() =>
                                        handleSelect(addr.name, addr.locationId)
                                      }
                                      checked={addr.name === address}
                                    />
                                    <span className="">{addr.name}</span>
                                  </label>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            {errors?.pickup_zip_code && (
              <span className="text-xs text-red-700">
                {errors?.pickup_zip_code.message}
              </span>
            )}
          </div>
          <hr className="mx-3" />
          <div className="flex">
            <div className="flex-col w-full">
              <div className="p-4 pb-0">
                <label
                  className="block w-full mb-2 text-sm font-bold text-black"
                  htmlFor="pickup-date"
                >
                  Pickup Date
                </label>
                <Controller
                  render={({ field: { ref, name, value, onChange } }) => (
                    <DatePickerInput
                      name={name}
                      ref={ref}
                      handleDateChange={(e) => onChange(e)}
                      selectedDate={value ?? ''}
                      isDisabled={isDateDisable ?? false}
                      classStyle="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                    />
                  )}
                  name="pickup_date"
                  control={control}
                />
                {errors.pickup_date && (
                  <span className="text-xs text-red-700">
                    {errors.pickup_date.message}
                  </span>
                )}
              </div>
              <div className="p-4 mb-3">
                <label>
                  <input
                    {...register('is_pickup_flexible')}
                    type="checkbox"
                    defaultChecked={
                      !isPickupFlexible && !getValues('pickup_date')
                    }
                  />
                  <span className="ml-1 text-sm">
                    Flexible (within next week)
                  </span>
                </label>
                {errors.is_pickup_flexible && (
                  <span className="text-xs text-red-700">
                    {errors.is_pickup_flexible.message}
                  </span>
                )}
              </div>
            </div>
            <div className="flex-col w-full">
              <div className="p-4 pb-0">
                <label
                  className="block w-full mb-2 text-sm font-bold text-black"
                  htmlFor="pickup-time"
                >
                  Pickup Time
                </label>
                <Controller
                  render={({ field: { ref, name, value, onChange } }) => (
                    <TimePickerInput
                      name={name}
                      ref={ref}
                      handleDateChange={(e) => onChange(e)}
                      selectedDate={value ?? ''}
                      isDisabled={isTimeDisable ?? false}
                      classStyle="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                    />
                  )}
                  name="pickup_time"
                  control={control}
                />
                {errors.pickup_time && (
                  <span className="text-xs text-red-700">
                    {errors.pickup_time.message}
                  </span>
                )}
              </div>

              <div className="p-4 mb-3">
                <label>
                  <input
                    {...register('is_pickup_time_flexible')}
                    type="checkbox"
                    defaultChecked={
                      !isPickupTimeFlexible && !getValues('pickup_time')
                    }
                  />
                  <span className="ml-1 text-sm">Flexible (8am-5pm)</span>
                </label>
                {errors.is_pickup_time_flexible && (
                  <span className="text-xs text-red-700">
                    {errors.is_pickup_time_flexible.message}
                  </span>
                )}
              </div>
            </div>
            <div className="w-full p-4 pb-0 mb-3">
              <label
                className="block w-full mb-2 text-sm font-bold text-black"
                htmlFor="pickup-time"
              >
                Pickup Close Time
              </label>
              <Controller
                render={({ field: { ref, name, value, onChange } }) => (
                  <TimePickerInput
                    name={name}
                    ref={ref}
                    handleDateChange={(e) => onChange(e)}
                    selectedDate={value ?? ''}
                    classStyle="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                    isDisabled={isTimeDisable}
                  />
                )}
                name="pickup_close_time"
                control={control}
              />
              {errors.pickup_close_time && (
                <span className="text-xs text-red-700">
                  {errors.pickup_close_time.message}
                </span>
              )}
            </div>
          </div>
          <hr className="mx-3" />
          <div className="p-4 ">
            <label
              className="block w-full mb-2 text-sm font-bold text-black"
              htmlFor="pickup-date"
            >
              Select Pickup Services
            </label>
            <div className="grid grid-cols-4 mt-6">
              {PICKUP_SERVICE_OPTION.map((pickupOption) => {
                return (
                  <React.Fragment key={pickupOption.value}>
                    <div className="mb-4" key={pickupOption.value}>
                      <label>
                        {pickupOption.value === '57fb6370b13762ee6a3f94d0' &&
                        !pickupServiceOptions?.length ? (
                          <input
                            {...register('pickup_service_options')}
                            type="checkbox"
                            value={pickupOption.value}
                            defaultChecked
                          />
                        ) : (
                          <input
                            {...register('pickup_service_options')}
                            type="checkbox"
                            value={pickupOption.value}
                          />
                        )}
                        <span className="ml-1 text-sm">
                          {pickupOption.label}
                        </span>
                      </label>
                    </div>
                    {pickUpServices?.includes(PICKUP_LIMITED_ACCESS) &&
                    pickupOption.value === PICKUP_LIMITED_ACCESS ? (
                      <div className="flex flex-col w-1/2 mb-3">
                        <Controller
                          name="pickup_limited_access_type"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={PICKUP_LIMITED_ACCESS_TYPES}
                              className="w-full text-xs font-semibold leading-none bg-white border-gray-200 outline-none appearance-none"
                            />
                          )}
                        />
                      </div>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </div>

            {errors.pickup_service_options && (
              <span className="text-xs text-red-700">
                {errors.pickup_service_options.message}
              </span>
            )}
            {errors?.pickup_limited_access_type && (
              <span className="text-xs text-red-700">
                {errors?.pickup_limited_access_type?.message}
              </span>
            )}
          </div>
          <hr className="mx-3" />
          <div className="p-4 mb-3">
            <label
              className="block w-full mb-2 text-sm font-bold text-black"
              htmlFor="pickup-date"
            >
              Special pickup instructions
            </label>
            <textarea
              {...register('pickup_special_instructions')}
              className="w-full px-2 py-2 pb-10 leading-tight bg-gray-100 border border-gray-200 border-solid shadow appearance-none"
              placeholder="Write your instructions here"
            />
          </div>
          {errors.pickup_special_instructions && (
            <span className="text-xs text-red-700">
              {errors.pickup_special_instructions.message}
            </span>
          )}
        </>
      )}
      {!IsFromList && (
        <div className="p-4 mt-10">
          <button
            type="button"
            onClick={() => saveNewAddress()}
            className="px-4 py-2 text-sm font-semibold text-white bg-navy-500"
          >
            Save Pickup Address
          </button>
        </div>
      )}
    </>
  );
};

ShipperSinglePickUP.propTypes = {
  prevStep: PropTypes.func.isRequired,
  formContent: PropTypes.any.isRequired
};

export default ShipperSinglePickUP;
