const SalesRepColumns = [
  { displayName: 'Company Name', value: 'company', isDefault: true },
  { displayName: 'Pickup Date', value: 'pickup_date', isDefault: true },
  { displayName: 'Delivery Date', value: 'delivery_date', isDefault: true },
  { displayName: 'Zipcode(P)', value: 'pickup_zip_code', isDefault: true },
  { displayName: 'Zipcode(D)', value: 'delivery_zip_code', isDefault: true },
  { displayName: 'Status', value: 'bidStatus', isDefault: true },
  { displayName: 'Amount', value: 'estimate', isDefault: false },
  { displayName: 'Carrier Name', value: 'carrier_type', isDefault: false },
  {
    displayName: 'Carrier PRO#',
    value: 'trackingNumber',
    isDefault: false
  },
  {
    displayName: 'Tracking Number',
    value: 'internalTrackingNumber',
    isDefault: false
  },
  {
    displayName: 'carrierPrice',
    value: 'carrierPrice',
    isDefault: false
  },
  {
    displayName: 'Profit',
    value: 'profit',
    isDefault: false
  },
  {
    displayName: 'Margin',
    value: 'margin',
    isDefault: false
  }
];

const ShipperColumns = [
  { displayName: 'Pickup Date', value: 'pickup_date', isDefault: true },
  { displayName: 'Delivery Date', value: 'delivery_date', isDefault: true },
  { displayName: 'Zipcode(P)', value: 'pickup_zip_code', isDefault: true },
  { displayName: 'Zipcode(D)', value: 'delivery_zip_code', isDefault: true },
  { displayName: 'Status', value: 'bidStatus', isDefault: true },
  { displayName: 'Amount', value: 'estimate', isDefault: false },
  { displayName: 'Carrier Name', value: 'carrier_type', isDefault: false },
  {
    displayName: 'Carrier PRO#',
    value: 'trackingNumber',
    isDefault: false
  },
  {
    displayName: 'Tracking Number',
    value: 'internalTrackingNumber',
    isDefault: false
  }
];

export { SalesRepColumns, ShipperColumns };
