import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import moment from 'moment';
import Select from 'react-select';
import { IoLocationSharp } from 'react-icons/io5';

import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId
} from 'react-places-autocomplete';
import DatePickerInput from '../../../components/DatePickerInput/DatePickerInput';
import TimePickerInput from '../../../components/TimePickerInput/TimePickerInput';

import {
  DELIVERY_LIMITED_ACCESS_TYPES,
  DELIVERY_SERVICE_OPTION,
  DELIVERY_TRADESHOW_TYPES
} from '../../../constants/quote.constants';
import {
  DELIVERY_BUSSINESS_WITH_DOCK_FORKLIFT,
  DELIVERY_INSIDE_PICKUP,
  DELIVERY_LIMITED_ACCESS,
  DELIVERY_RESIDENTIAL,
  DELIVERY_TRADE_SHOW,
  LTL_FREIGHT
} from '../../../constants/service_type.constants';

import { ReactComponent as LeftArrowIcon } from '../../../assets/images/left_arrow.svg';
import { loadAddressService } from '../../../services/quote.service';
import { useAuthContext } from '../../../contexts/auth.context';
import { getAddressFromLatLong } from '../../../services/geo.service';
import { addLocationService } from '../../../services/shipper.service';
import { formatPickupDeliveryTime } from '../../../utils/date_conversion.util';
import { deliveryValidationSchema } from '../../../utils/quote.util';
import Roles from '../../../constants/roles.constants';

const ShipperSingleDelivery = ({ prevStep, formContent }) => {
  const validationSchema = deliveryValidationSchema;

  const [isDateDisable, setDateDisable] = useState(false);
  const [isTimeDisable, setTimeDisable] = useState(false);
  const [deliveryMessage, setdeliveryMessage] = useState(false);
  const [address, setAddress] = useState('');
  const [userAddress, setUserAddress] = useState([]);
  const [IsChangeAdd, setIsChangeAdd] = useState(false);
  const [IsFromList, setIsFromList] = useState(false);

  const { authUser } = useAuthContext();

  const serviceType = formContent.one.service_type;
  const pickupDate = formContent.two.pickup_date;
  const isPickupFlexible = formContent.two.is_pickup_flexible;

  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useFormContext();

  const deliveryDate = watch('delivery_date');
  const isDeliveryFlexible = watch('is_delivery_flexible');

  const isDeliveryTimeFlexible = watch('is_delivery_time_flexible');

  const deliveryServices = watch('delivery_service_options');
  const deliveryServiceOptions = watch('delivery_service_options');

  const handleSelect = async (value, placeId) => {
    try {
      let id = placeId;
      if (!id) {
        const result = await geocodeByAddress(value);
        id = result[0].place_id;
      }
      const [place] = await geocodeByPlaceId(id.trim());

      const { addr, postcode, city, state, country } =
        await getAddressFromLatLong(
          place.geometry.location.lat(),
          place.geometry.location.lng(),
          value,
          place.postcode_localities
        );

      setAddress(value);
      setValue(
        'delivery_to',
        `${addr} | ${postcode}, ${city}, ${state}, ${country}`
      );

      setValue('delivery_formatted_address', `${value} | ${placeId}`);
      setValue('delivery_zip_code', postcode);
      setValue('delivery_state', state);
    } catch (error) {
      console.log(error);
    }
  };

  async function loadAddresses() {
    try {
      const companyId =
        authUser?.user?.usertype === Roles.SHIPPER
          ? authUser?.user?.companyId?.id
          : getValues('company')?.id;
      const response = await loadAddressService(companyId, 'delivery');

      setUserAddress(response.data.data);
      const defaultAddress = response.data.data?.filter?.(
        (addr) => addr.isDefault
      )?.[0];
      const delAdd = getValues('delivery_formatted_address')?.split('|')?.[0];
      if (delAdd) {
        setAddress(delAdd);
      } else {
        handleSelect(
          defaultAddress?.name,
          defaultAddress?.locationId,
          defaultAddress
        );
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
    loadAddresses();
    setAddress(getValues('delivery_formatted_address')?.split('|')?.[0]);
  }, []);

  useEffect(() => {
    if (!isPickupFlexible && pickupDate) {
      if (deliveryDate && moment(deliveryDate).isBefore(pickupDate)) {
        setValue('delivery_date', '', { shouldValidate: true });
        toast.error('Delivery date can not be prior to pick up date!');
      }
    }
  }, [deliveryDate, pickupDate, isPickupFlexible]);

  useEffect(() => {
    const addrData = userAddress?.find(
      (del) => del?.delivery_formatted_address?.trim() === address
    );

    if (addrData) {
      setValue(
        'delivery_time',
        addrData?.delivery_time !== 'flexible time'
          ? new Date(moment(addrData.delivery_time, 'h:mm a').format())
          : ''
      );
      setValue(
        'delivery_close_time',
        addrData?.delivery_close_time
          ? new Date(moment(addrData.delivery_close_time, 'h:mm a').format())
          : ''
      );
      setValue(
        'is_delivery_time_flexible',
        addrData?.is_delivery_time_flexible
      );
      setValue('delivery_service_options', addrData?.delivery_service_options);
      setValue(
        'delivery_special_instructions',
        addrData?.delivery_special_instructions
      );

      addrData?.service_options_type?.forEach((serviceOptionType) => {
        if (
          ['636a104aeb0cee8407829bc8', '636a104aeb0cee8407829bc9'].includes(
            serviceOptionType
          )
        ) {
          const matchedService = DELIVERY_TRADESHOW_TYPES.find(
            (type) => type.value === serviceOptionType
          );
          setValue('delivery_tradeshow_type', matchedService);
        } else {
          const matchedService = DELIVERY_LIMITED_ACCESS_TYPES.find(
            (type) => type.value === serviceOptionType
          );
          setValue('delivery_limited_access_type', matchedService);
        }
      });
    }

    if (userAddress?.length && addrData) {
      setIsFromList(true);
      setValue('deliveryLocation', addrData?.id);
    } else if (!getValues('deliveryLocation')) {
      setIsFromList(false);
      setValue('deliveryLocation', null);
    } else {
      setIsFromList(false);
    }
  }, [address]);

  useEffect(() => {
    if (isDeliveryFlexible) {
      setValue('delivery_date', '', { shouldValidate: true });
      setDateDisable(true);
    } else {
      setDateDisable(false);
    }
  }, [isDeliveryFlexible]);

  useEffect(() => {
    if (isDeliveryTimeFlexible) {
      setValue('delivery_time', '', { shouldValidate: true });
      setValue('delivery_close_time', '', { shouldValidate: true });
      setTimeDisable(true);
    } else {
      setTimeDisable(false);
    }
  }, [isDeliveryTimeFlexible]);

  useEffect(() => {
    if (typeof deliveryServices === 'string' && deliveryServices.length) {
      setValue('delivery_service_options', [deliveryServices]);
    }
    if (deliveryServices && deliveryServices.length) {
      if (
        serviceType === LTL_FREIGHT &&
        deliveryServices.includes(DELIVERY_BUSSINESS_WITH_DOCK_FORKLIFT) &&
        deliveryServices.includes(DELIVERY_RESIDENTIAL)
      ) {
        setValue(
          'delivery_service_options',
          deliveryServices.filter(
            (opt) =>
              opt !== DELIVERY_BUSSINESS_WITH_DOCK_FORKLIFT &&
              opt !== DELIVERY_RESIDENTIAL
          ),
          {
            shouldValidate: true
          }
        );
        toast.error(
          'You can select either Business with a Dock or Forklift or Residential at a time!'
        );
      }
      if (!deliveryServices.includes(DELIVERY_LIMITED_ACCESS)) {
        setValue('delivery_limited_access_type', undefined);
      }
      if (!deliveryServices.includes(DELIVERY_TRADE_SHOW)) {
        setValue('delivery_tradeshow_type', undefined);
      }
      if (
        deliveryServices.includes(DELIVERY_INSIDE_PICKUP) &&
        !deliveryMessage
      ) {
        toast.success(
          'Please fill up special instructions for us at the bottom of this page, since you have selected inside delivery.'
        );
        setdeliveryMessage(true);
      }
    }
  }, [deliveryServices]);

  const saveNewAddress = async () => {
    try {
      const companyId =
        authUser?.user?.usertype === Roles.SHIPPER
          ? authUser?.user?.companyId?.id
          : getValues('company')?.id;
      const deliveryData = {
        locationId: getValues('delivery_formatted_address')
          ?.split('|')?.[1]
          ?.trim(),
        name: getValues('delivery_formatted_address')?.split('|')?.[0]?.trim(),
        delivery_formatted_address: getValues('delivery_formatted_address')
          ?.split('|')?.[0]
          ?.trim(),
        delivery_to: getValues('delivery_to'),
        delivery_zip_code: getValues('delivery_zip_code'),
        type: 'delivery',
        companyId,
        delivery_time: formatPickupDeliveryTime(
          getValues('delivery_time'),
          getValues('is_delivery_time_flexible')
        ),
        delivery_close_time: !getValues('delivery_close_time')
          ? ''
          : formatPickupDeliveryTime(getValues('delivery_close_time'), false),
        delivery_service_options: getValues('delivery_service_options'),
        service_options_type: [getValues('delivery_limited_access_type')]
          ?.filter((type) => type)
          .map((option) => option.value),
        is_delivery_time_flexible: getValues('is_delivery_time_flexible'),
        delivery_special_instructions: getValues(
          'delivery_special_instructions'
        )
      };

      validationSchema
        .validate(deliveryData, { abortEarly: false })
        .then(async () => {
          const response = await addLocationService(deliveryData);
          toast.success('Delivery address saved successfully!');
          setUserAddress([...userAddress, response?.data?.data]);
          setIsFromList(true);
        })
        .catch((error) => {
          console.log(error);
          return toast.error('Invalid data');
        });
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const suggestionsDivRef = useRef(null);

  const onAddressChange = (e) => {
    suggestionsDivRef.current.scrollTop = 0;

    setIsChangeAdd(true);
    setAddress(e);
  };
  return (
    <>
      <div className="relative p-4 mb-6 text-navy-500">
        <div
          onClick={prevStep}
          onKeyPress={() => {}}
          role="button"
          tabIndex="-1"
        >
          <LeftArrowIcon className="absolute inset-y-0 left-0 flex items-center w-6 h-6 mt-6 font-bold text-navy-500" />
        </div>
        <h3 className="ml-6 text-3xl font-bold">Select delivery option</h3>
      </div>

      <div className="p-4 mb-3">
        <label
          className="block w-full mb-2 text-sm font-bold text-black"
          htmlFor="ship-from"
        >
          Ship To
        </label>
        <PlacesAutocomplete
          value={address ?? ''}
          onChange={(e) => onAddressChange(e)}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div>
              <div className="flex items-center gap-3">
                <input
                  className="w-full px-2 py-3 text-xs leading-tight bg-gray-100 border border-gray-200 border-solid shadow appearance-none"
                  {...getInputProps({ placeholder: 'Type address' })}
                />
                {!IsChangeAdd && (
                  <div className="inline-flex justify-end w-1/5">
                    <button
                      type="button"
                      onClick={() => setIsChangeAdd(true)}
                      className="flex items-center justify-center h-10 gap-1 px-2 py-2 text-sm break-words whitespace-normal border rounded-sm max-w-max border-navy-500 hover:bg-navy-600 hover:text-white active:bg-navy-700 text-navy-500 hover:border-navy-600 active:border-navy-700"
                    >
                      <IoLocationSharp size={20} />
                      Change Address
                    </button>
                  </div>
                )}
              </div>
              <div className="overflow-scroll max-h-48" ref={suggestionsDivRef}>
                {IsChangeAdd && (
                  <div className="px-2 py-2 mt-2 space-y-3 bg-gray-100 border border-gray-200 border-solid shadow-lg">
                    <div className="mt-3 bg-gray-100">
                      {suggestions.length > 0 && (
                        <>
                          <div className="flex flex-col gap-2">
                            <p className="text-gray-400">Other Address</p>
                            {loading ? <div>...loading</div> : null}
                            {suggestions.map((suggestion) => {
                              return (
                                <label
                                  {...getSuggestionItemProps(suggestion)}
                                  key={suggestion.placeId}
                                >
                                  <input
                                    {...register(`selectedAddress`)}
                                    type="radio"
                                    value={suggestion.id}
                                  />

                                  <span className="">
                                    {suggestion.description}
                                  </span>
                                </label>
                              );
                            })}
                          </div>
                          <hr className="mt-3" />
                        </>
                      )}
                    </div>
                    <p className="text-gray-400">Default Address</p>
                    <div className="flex flex-col mt-4 mb-4">
                      {userAddress
                        ?.filter((addr) => addr.isDefault)
                        ?.map((addr) => {
                          return (
                            <label key={addr.id}>
                              <input
                                {...register(`selectedAddress`)}
                                type="radio"
                                value={addr.id}
                                checked={addr.name === address}
                                onChange={() =>
                                  handleSelect(addr.name, addr.locationId)
                                }
                              />
                              <span>{addr.name}</span>
                            </label>
                          );
                        })}
                    </div>
                    <hr />
                    <div>
                      <p className="text-gray-400">Saved Address</p>
                      <div className="flex flex-col gap-2 mt-4 mb-4 ">
                        {userAddress
                          ?.filter((addr) => !addr.isDefault)
                          ?.map((addr) => {
                            return (
                              <label key={addr.id}>
                                <input
                                  {...register(`selectedAddress`)}
                                  type="radio"
                                  value={addr.id}
                                  onChange={() =>
                                    handleSelect(addr.name, addr.locationId)
                                  }
                                  checked={addr.name === address}
                                />
                                <span className="">{addr.name}</span>
                              </label>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
      <hr className="mx-3" />
      <div className="flex">
        <div className="flex-col w-full">
          <div className="p-4 pb-0">
            <label
              className="block w-full mb-2 text-sm font-bold text-black"
              htmlFor="delivery-date"
            >
              Delivery Date
            </label>
            <Controller
              render={({ field: { ref, name, value, onChange } }) => (
                <DatePickerInput
                  name={name}
                  ref={ref}
                  handleDateChange={(e) => onChange(e)}
                  selectedDate={value ?? ''}
                  isDisabled={isDateDisable ?? false}
                  classStyle="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                />
              )}
              name="delivery_date"
              control={control}
            />
            {errors.pickup_date && (
              <span className="text-xs text-red-700">
                {errors.pickup_date.message}
              </span>
            )}
          </div>
          <div className="p-4 mb-3">
            <label>
              <input
                {...register('is_delivery_flexible')}
                type="checkbox"
                defaultChecked={
                  !isDeliveryFlexible && !getValues('delivery_date')
                }
              />
              <span className="ml-1 text-sm">Flexible (within next week)</span>
            </label>
            {errors.is_delivery_flexible && (
              <span className="text-xs text-red-700">
                {errors.is_delivery_flexible.message}
              </span>
            )}
          </div>
        </div>
        <div className="flex-col w-full">
          <div className="p-4 pb-0">
            <label
              className="block w-full mb-2 text-sm font-bold text-black"
              htmlFor="delivery-time"
            >
              Delivery Time
            </label>
            <Controller
              render={({ field: { ref, name, value, onChange } }) => (
                <TimePickerInput
                  name={name}
                  ref={ref}
                  handleDateChange={(e) => onChange(e)}
                  selectedDate={value ?? ''}
                  isDisabled={isTimeDisable ?? false}
                  classStyle="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                />
              )}
              name="delivery_time"
              control={control}
            />
            {errors.delivery_time && (
              <span className="text-xs text-red-700">
                {errors.delivery_time.message}
              </span>
            )}
          </div>

          <div className="p-4 mb-3">
            <label>
              <input
                {...register('is_delivery_time_flexible')}
                type="checkbox"
                defaultChecked={
                  !isDeliveryTimeFlexible && !getValues('delivery_time')
                }
              />
              <span className="ml-1 text-sm">Flexible (8am-5pm)</span>
            </label>
            {errors.is_delivery_time_flexible && (
              <span className="text-xs text-red-700">
                {errors.is_delivery_time_flexible.message}
              </span>
            )}
          </div>
        </div>
        <div className="w-full p-4 pb-0 mb-3">
          <label
            className="block w-full mb-2 text-sm font-bold text-black"
            htmlFor="pickup-time"
          >
            Delivery Close Time
          </label>
          <Controller
            render={({ field: { ref, name, value, onChange } }) => (
              <TimePickerInput
                name={name}
                ref={ref}
                handleDateChange={(e) => onChange(e)}
                selectedDate={value ?? ''}
                classStyle="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                isDisabled={isTimeDisable}
              />
            )}
            name="delivery_close_time"
            control={control}
          />
          {errors.delivery_close_time && (
            <span className="text-xs text-red-700">
              {errors.delivery_close_time.message}
            </span>
          )}
        </div>
      </div>
      <hr className="mx-3" />
      <div className="p-4 ">
        <label
          className="block w-full mb-2 text-sm font-bold text-black"
          htmlFor="delivery-date"
        >
          Select Delivery Services
        </label>
        <div className="grid grid-cols-4 mt-6">
          {DELIVERY_SERVICE_OPTION.map((deliveryOption) => {
            return (
              <React.Fragment key={deliveryOption.value}>
                <div className="mb-4" key={deliveryOption.value}>
                  <label>
                    {deliveryOption.value === '57fb6370b13762ee6a3f94d0' &&
                    !deliveryServiceOptions?.length ? (
                      <input
                        {...register('delivery_service_options')}
                        type="checkbox"
                        value={deliveryOption.value}
                        defaultChecked
                      />
                    ) : (
                      <input
                        {...register('delivery_service_options')}
                        type="checkbox"
                        value={deliveryOption.value}
                      />
                    )}
                    <span className="ml-1">{deliveryOption.label}</span>
                  </label>
                </div>
                {deliveryServices?.includes(DELIVERY_LIMITED_ACCESS) &&
                deliveryOption.value === DELIVERY_LIMITED_ACCESS ? (
                  <div className="flex w-1/2 mb-3 ">
                    <Controller
                      name="delivery_limited_access_type"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={DELIVERY_LIMITED_ACCESS_TYPES}
                          className="w-full text-xs font-semibold leading-none bg-white border-gray-200 outline-none appearance-none"
                        />
                      )}
                    />
                  </div>
                ) : null}
                {deliveryServices?.includes(DELIVERY_TRADE_SHOW) &&
                deliveryOption.value === DELIVERY_TRADE_SHOW ? (
                  <div className="flex w-1/2 mb-3 ">
                    <Controller
                      name="delivery_tradeshow_type"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={DELIVERY_TRADESHOW_TYPES}
                          className="w-full text-xs font-semibold leading-none bg-white border-gray-200 outline-none appearance-none"
                        />
                      )}
                    />
                  </div>
                ) : null}
              </React.Fragment>
            );
          })}
        </div>
        {errors.delivery_service_options && (
          <span className="text-xs text-red-700">
            {errors.delivery_service_options.message}
          </span>
        )}
        {errors?.delivery_limited_access_type && (
          <span className="block text-xs text-red-700">
            {errors?.delivery_limited_access_type?.message}
          </span>
        )}
        {errors?.delivery_tradeshow_type && (
          <span className="block text-xs text-red-700">
            {errors?.delivery_tradeshow_type?.message}
          </span>
        )}
      </div>
      <hr className="mx-3" />
      <div className="p-4 mb-3">
        <label
          className="block w-full mb-2 text-sm font-bold text-black"
          htmlFor="pickup-date"
        >
          Special Delivery Instructions
        </label>
        <textarea
          {...register('delivery_special_instructions')}
          className="w-full px-2 py-2 pb-10 leading-tight bg-gray-100 border border-gray-200 border-solid shadow appearance-none"
          placeholder="Write your instructions here"
        />
        {errors.delivery_special_instructions && (
          <span className="text-xs text-red-700">
            {errors.delivery_special_instructions.message}
          </span>
        )}
      </div>
      {!IsFromList && (
        <div className="p-4 mt-10">
          <button
            type="button"
            onClick={() => saveNewAddress()}
            className="px-4 py-2 text-sm font-semibold text-white bg-navy-500"
          >
            Save Delivery Address
          </button>
        </div>
      )}
    </>
  );
};

export default ShipperSingleDelivery;

ShipperSingleDelivery.propTypes = {
  prevStep: PropTypes.func.isRequired,
  formContent: PropTypes.any.isRequired
};
